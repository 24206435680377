import aboutMe from '../img/socials/about-me.png';
import angelist from '../img/socials/angelist.svg';
import badoo from '../img/socials/badoo.svg';
import bandcamp from '../img/socials/bandcamp.svg';
import behance from '../img/socials/behance.svg';
import bitbucket from '../img/socials/bitbucket.svg';
import bitly from '../img/socials/bitly.svg';
import campsite from '../img/socials/campsite.png';
import canva from '../img/socials/canva.svg';
import codecademy from '../img/socials/codecademy.svg';
import contactinbio from '../img/socials/contactinbio.png';
import cybrary from '../img/socials/cybrary.png';
import dailymotion from '../img/socials/dailymotion.svg';
import disqus from '../img/socials/disqus.svg';
import dribble from '../img/socials/dribble.svg';
import ebay from '../img/socials/ebay.svg';
import facebook from '../img/socials/facebook.svg';
import fanpop from '../img/socials/fanpop.svg';
import fiverr from '../img/socials/fiverr.svg';
import flickr from '../img/socials/flickr.svg';
import flipboard from '../img/socials/flipboard.svg';
import fotolog from '../img/socials/fotolog.png';
import foursquare from '../img/socials/foursquare.svg';
import gamespot from '../img/socials/gamespot.png';
import github from '../img/socials/github.svg';
import googleplus from '../img/socials/googleplus.svg';
import gravatar from '../img/socials/gravatar.svg';
import houzz from '../img/socials/houzz.svg';
import hubpages from '../img/socials/hubpages.svg';
import imgur from '../img/socials/imgur.svg';
import instagram from '../img/socials/instagram.svg';
import instructables from '../img/socials/instructables.png';
import keybase from '../img/socials/keybase.svg';
import kongregate from '../img/socials/kongregate.png';
import linkedin from '../img/socials/linkedin.svg';
import linktree from '../img/socials/linktree.png';
import medium from '../img/socials/medium.svg';
import mixcloud from '../img/socials/mixcloud.png';
import myspace from '../img/socials/myspace.svg';
import patreon from '../img/socials/patreon.svg';
import paypal from '../img/socials/paypal.svg';
import photobucket from '../img/socials/photobucket.svg';
import pinterest from '../img/socials/pinterest.svg';
import quora from '../img/socials/quora.svg';
import reddit from '../img/socials/reddit.svg';
import roblox from '../img/socials/roblox.svg';
import shorBy from '../img/socials/shor-by.png';
import slideshare from '../img/socials/slideshare.svg';
import snapchat from '../img/socials/snapchat.svg';
import soundcloud from '../img/socials/soundcloud.svg';
import spotify from '../img/socials/spotify.svg';
import steam from '../img/socials/steam.svg';
import swipop from '../img/socials/swipop.png';
import tapBio from '../img/socials/tap-bio.png';
import tiktok from '../img/socials/tiktok.svg';
import tripadvisor from '../img/socials/tripadvisor.svg';
import tumblr from '../img/socials/tumblr.svg';
import twitch from '../img/socials/twitch.svg';
import twitter from '../img/socials/twitter.svg';
import vimeo from '../img/socials/vimeo.svg';
import vine from '../img/socials/vine.svg';
import vkontakte from '../img/socials/vkontakte.png';
import wattpad from '../img/socials/wattpad.svg';
import wikipedia from '../img/socials/wikipedia.svg';
import wordpress from '../img/socials/wordpress.svg';
import youtube from '../img/socials/youtube.svg';

export const socialChannels = {
    ContactInBio: contactinbio,
    "about.me": aboutMe,
    angellist: angelist,
    badoo: badoo,
    bandcamp: bandcamp,
    behance: behance,
    bitbucket: bitbucket,
    bitly: bitly,
    campsite: campsite,
    canva: canva,
    codeacademy: codecademy,
    cybrary: cybrary,
    dailymotion: dailymotion,
    disqus: disqus,
    dribble: dribble,
    ebay: ebay,
    facebook: facebook,
    fanpop: fanpop,
    fiverr: fiverr,
    flickr: flickr,
    flipboard: flipboard,
    fotolog: fotolog,
    foursquare: foursquare,
    gamespot: gamespot,
    github: github,
    googleplus: googleplus,
    gravatar: gravatar,
    houzz: houzz,
    hubpages: hubpages,
    imgur: imgur,
    instagram: instagram,
    instructables: instructables,
    keybase: keybase,
    kongregate: kongregate,
    linkedin: linkedin,
    linktree: linktree,
    medium: medium,
    mixcloud: mixcloud,
    myspace: myspace,
    patreon: patreon,
    paypal: paypal,
    photobucket: photobucket,
    pinterest: pinterest,
    quora: quora,
    reddit: reddit,
    roblox: roblox,
    "shor.by": shorBy,
    slideshare: slideshare,
    snapchat: snapchat,
    soundcloud: soundcloud,
    spotify: spotify,
    steam: steam,
    swipop: swipop,
    "tap.bio": tapBio,
    tiktok: tiktok,
    tripadvisor: tripadvisor,
    tumblr: tumblr,
    twitch: twitch,
    twitter: twitter,
    vimeo: vimeo,
    vine: vine,
    vkontakte: vkontakte,
    wattpad: wattpad,
    wikipedia: wikipedia,
    wordpress: wordpress,
    youtube: youtube,
}