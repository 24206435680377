import React, { useEffect, useState, useRef } from 'react';
import noImage from '../../img/socials/no-image.svg';
import { socialChannels } from '../../constants';
import { copy, copyIcon, download } from '../../img/svg-icons';
import { CSVLink, CSVDownload } from 'react-csv';
import email from '../../img/icons/email.svg';
import phone from '../../img/icons/phone.svg';
import './styles.scss';

export default ({ result, url }) => {
  const [copied, setCopied] = useState(false);
  const inputElement = useRef(null);
  const [socials, setSocials] = useState(null);
  const [emails, setEmails] = useState(null);
  const [phones, setPhones] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [showCompanyEmails, setShowCompanyEmails] = useState(false);
  const [companyEmails, setCompanyEmails] = useState(null);

  useEffect(() => {
    const data = [];
    const csv = [['emails', 'phones']];
    if (result.emails) {
      setEmails(result.emails);
      const cleanUrl = url.slice(url.indexOf('://')+(url.indexOf('://') ? 3 : 0), url.indexOf('.')) 
      setCompanyEmails(result.emails.filter((item) => item.includes(cleanUrl)))
      result.emails.map((item, index) => {
        csv[index + 1] ? csv[index + 1].push(item) : (csv[index + 1] = [item]);
      });
    }
    if (result.phones) {
      setPhones(result.phones);
      result.phones.map((item, index) => {
        if (csv[index + 1]) {
          !csv[index + 1][0] && csv.push('');
          csv[index + 1].push(item);
        } else {
          csv[index + 1] = ['', item];
        }
      });
    }
    if (result.socials) {
      Object.entries(result.socials).map((item, index) => {
        item[1].map((subitem, subindex) => {
          data.push({ icon: socialChannels[item[0]] || noImage, value: subitem, name: item[0] });
          if (csv[subindex + 1]) {
            index === 0 && !csv[subindex + 1][0] && csv[subindex + 1].push('');
            index === 0 && !csv[subindex + 1][1] && csv[subindex + 1].push('');
            csv[subindex + 1].push(subitem);
          } else {
            const subArray = ['', ''];
            for (let i = 0; i <= index; i++) {
              i === index ? subArray.push(subitem) : subArray.push('');
            }
            csv[subindex + 1] = subArray;
          }
        });
        csv[0].push(item[0]);
      });
    }
    setCsvData(csv);
    setSocials(data);
  }, [result]);

  useEffect(() => {
    copied && setTimeout(() => setCopied(false), 5000);
  }, [copied]);

  const handleInputClick = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    const inputDom = inputElement.current;
    if (!inputDom) {
      return;
    }
    navigator.clipboard.writeText(value || '');
    inputDom.focus();
    inputDom.setSelectionRange(0, value.length);
    document.execCommand('copy');
    setCopied(true);
  };

  return (
    <div className="results-container">
      {socials && socials.length > 0 && (
        <div className="socials-contaner">
          <h5 className="d-flex">
            <div style={{margin: 'auto 0'}}>Social Media Links</div>
            <div className="buttons-container">
              {/* <button className='button is-outline button-outline'><p>{copy} ALL</p></button> */}
              <CSVLink data={csvData} className="button download-btn" filename={`pull-contacts-${url}.csv`}>
                {download} CSV
              </CSVLink>
            </div>
          </h5>
          <div className="values-container">
            {socials.map(item => (
              <div className="value-row">
                <img src={item.icon} alt="email" />
                <div className="w-100">
                  <div className="value-name">{item.name}</div>
                  <div className="d-flex">
                    <div className="value-text social text-truncate" title={item.value}>
                      <a href={item.value} target="_blank">
                        {item.value}
                      </a>
                    </div>
                    {/* <input ref={inputElement} value={item.value} style={{visibility: 'hidden', height: 0, width: 0}} />
                    <div className='copy' onClick={(e) => {handleInputClick(e, item.value)}}>{copyIcon}</div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {emails && emails.length > 0 && (
        <div className="socials-contaner">
          <h5>
            Emails
            <label className="checkbox">
              <input type="checkbox" checked={showCompanyEmails} onChange={() => setShowCompanyEmails(!showCompanyEmails)} />
              Filter for this domain
            </label>
          </h5>
          <div className="values-container">
            {(showCompanyEmails ? companyEmails : emails).map(item => (
              <div className="value-row">
                <img src={email} alt="email" />
                <div className="value-text text-truncate">
                  <a href={`mailto:${item}`}>{item}</a>
                </div>
                {/* <input ref={inputElement} value={item} style={{visibility: 'hidden', height: 0, width: 0}} />
                <div className='copy' onClick={(e) => {handleInputClick(e, item)}}>{copyIcon}</div> */}
              </div>
            ))}
          </div>
        </div>
      )}
      {phones && phones.length > 0 && (
        <div className="socials-contaner">
          <h5>Phones</h5>
          <div className="values-container">
            {phones.map(item => (
              <div className="value-row">
                <img src={phone} alt="phone" />
                <div className="value-text text-truncate">
                  <a href={`tel:${item}`}>{item}</a>
                </div>
                {/* <input ref={inputElement} value={item} style={{visibility: 'hidden', height: 0, width: 0}} />
                <div className='copy' onClick={(e) => {handleInputClick(e, item)}}>{copyIcon}</div> */}
              </div>
            ))}
          </div>
        </div>
      )}
      {copied && (
        <div className="copied">
          <p>Copied!</p>
        </div>
      )}
    </div>
  );
};
