import React, { useState } from 'react';
import Layout from '../components/layout';
import Result from '../components/result';
import Modal from '../components/modal';
import axios from 'axios';
import CTA from '../components/cta';
import './styles.scss';
export const isValidUrl = url =>
  /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\/+~#=?]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\/+.~#?&//=?]*)/g.test(url);
export const isValidDomain = domain =>
  /^(?:[a-z0-9:%._\/+~#=?](?:[a-z0-9-:%._\/+~#=?]{0,61}[a-z0-9:%._\/+~#=?])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
    domain,
  );

export default props => {
  const [url, setUrl] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [result, setResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleCaptureOnEnter = e => {
    if (e.key === 'Enter') {
      if (isValidUrl(url.trim()) || isValidDomain(url.trim())) {
        handleScan();
      } else {
        setErrorMessage('Please enter valid URL');
      }
    }
  };

  const handleChange = e => {
    setUrl(e.target.value);
  };

  const handleScan = async () => {
    if (!isValidUrl(url.trim()) && !isValidDomain(url.trim())) {
      setErrorMessage('Please enter valid URL');
      return;
    }
    setRequesting(true);
    setErrorMessage('');
    setResult(null);
    try {
      const res = await axios(`https://api.hexomatic.com/pullContacts`, {
        method: 'POST',
        data: { source: url.trim() },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (res && res.status === 200 && res.data && res.data.result && res.data.result.data) {
        setResult(res.data.result.data);
        setRequesting(false);
      } else if (res && res.data && res.data.result && res.data.result.error) {
        setRequesting(false);
        res.data.result.error === 'too many requests' ? setOpenModal(true) : setErrorMessage('No contacts detected.');
      }
    } catch (e) {
      setRequesting(false);
      setErrorMessage('No contacts detected.');
    }
  };

  const modalToggle = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <Layout
        title={'Scrape Emails Phone Numbers and Social Links from Website URL'}
        metaName={'description'}
        metaContent={
          'Quick and easy email address, phone number and social media link scraper. Get contact information in minutes.'
        }
        hideIllustrations={true}
      >
        <h1 className="page-title">Pull contact information in minutes</h1>
        <h2 className="page-subtitle">
          Quick and easy email address, phone number and social media link scraper. Search for Emails, Phone Numbers and
          Social Links within a URL.
        </h2>

        <div className="form-container field is-grouped">
          <input
            className="input is-rounded inputBar"
            type="text"
            placeholder="Type page URL"
            value={url}
            onChange={handleChange}
            onKeyPress={handleCaptureOnEnter}
          />

          <div className="buttons is-block btn_go">
            <button className="button is-primary is-outlined is-medium is-rounded" onClick={handleScan}>
              GO
            </button>
          </div>
        </div>
        {requesting && (
          <div className="control" style={{ paddingTop: '10px' }}>
            <div className="is-flex" style={{ justifyContent: 'center', textAlign: 'center' }}>
              <progress className="progress is-small is-primary" max="100" style={{ maxWidth: '750px' }}>
                5%
              </progress>
            </div>
          </div>
        )}
        {errorMessage && (
          <div
            className="is-flex"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              paddingTop: '20px',
            }}
          >
            <article class="message is-danger" style={{ maxWidth: '300px' }}>
              <div class="message-body">{errorMessage}</div>
            </article>
          </div>
        )}
        {result && <Result result={result} url={url} />}
        <CTA />
      </Layout>
      <Modal
        isOpen={openModal}
        modalToggle={modalToggle}
        title="Login to continue"
        onButtonClick={() => (window.location.href = 'https://hexomatic.com/#registration')}
        buttonText={'Unlock more searches'}
      >
        <p className="text-center">You have reached your search limit for the day.</p>
        <p className="text-center">
          PullContacts is powered by{' '}
          <a href="https://hexomatic.com" className="purple-link">
            Hexomatic
          </a>
          , <br />
          the no-code work automation platform. &#128640;
        </p>
        <p className="text-center">
          <a href="https://hexomatic.com/#registration" className="sign-up">
            Sign up
          </a>{' '}
          <span className="bold">for Hexomatic account</span> to unlock more contact searches via the
          PullContacts automation and 60+ ready made growth hacking automations.{' '}
        </p>
      </Modal>
    </>
  );
};
